<template>
  <!-- <div v-if="isPermission"> -->
  <div>
    <div class="row" style="margin: 5px">
      <div class="col-md-10 col-9">
        <h2 class="font-weight-normal">
          {{ $t('checkStockDoc') }}
        </h2>
      </div>
      <div class="col-md-2 col-3 text-right align-text-center">
        <a id="checkStockDetailBackButton" @click="$router.go(-1)">
          <CButton>
            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
          </CButton>
        </a>
      </div>
    </div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>

          <div class="row">
            <div class="col-7 col-lg-10">
              <h4 class="font-weight-normal">
                {{ $t('information') }}
              </h4>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <h6 id="checkStockDetailDocNo" class="font-weight-normal text-left text-dark ml-4">
                {{ $t('documentNo') }} : {{ detail.checkStockNo }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 id="checkStockDetailCreatedBy" class="font-weight-normal text-right text-dark">
                {{ $t('username') }} : {{ createdBy }}
              </h6>
            </div>
          </div>
          <div class="row mt-2">
            <CCol style="margin-top: 8px;" col="2">
              <h6 class="text-dark ml-4">
                {{ $t('checkStockName') }} :
              </h6>
            </CCol>
            <CCol sm="10" col="9">
              <input id="checkStockDetailDocNote" class="row form-control text-dark" style="margin-left: 1px;" v-model="detail.note"
                :placeholder="$t('checkStockName')">
              </input>
            </CCol>
          </div>
        </CCardBody>
      </CCard>
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10">
              <h4 class="font-weight-dark">
                {{ $t('checkStockProduct') }}
              </h4>
              <p>
                {{ $t('checkStockDetail') }}
              </p>
            </div>
            <div class="col-md-2 text-right">
              <CButton id="checkStockDetailAddProductButton" v-if="disabledField === false" class="btn btn-outline-success btn-block" style="display: flex; align-items: center; justify-content: center;" @click="AddModal()">
                <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>  {{ $t('addProduct') }}
              </CButton>
            </div>
          </div>

          <CRow class="mt-3">
            <CCol sm="12" lg="12">
              <table id="checkStockDetailProductTable" style="white-space: nowrap" v-if="documentItems && documentItems.length != 0"
                class="table table-sm table-responsive table-bordered">
                <thead>
                  <tr>
                    <th style="width: 10px" class="text-dark text-center font-weight-normal">#</th>
                    <th></th>
                    <th class="text-dark font-weight-normal">{{ $t('pluCode') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('productName') }}</th>
                    <th class="text-dark text-right font-weight-normal">{{ $t('checkCount') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in documentItems" :key="index">
                    <td :id="'checkStockDetailProductIndex' + index" style="width: 2%" class="text-center" :value="(item.orderIndex = index)">
                      <div style="margin-top: 6px" class="text-dark">{{ index + 1 }}</div>
                    </td>
                    <td style="width: 5%" :id="'checkStockDetailProductImage' + index">
                      <div v-if="item.remoteImagePath" :style="{
            'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
            'background-size': 'cover',
            'background-position': 'center center',
            'width': 'auto',
            'border-radius': '2px',
            'aspect-ratio': '1.3',
            'min-width': '50px',
          }"></div>
                      <div v-else style="border-radius: 2px" :style="{
            'aspect-ratio': '4 / 3',
            'min-width': '50px',
            backgroundColor: item.indexColor,
          }"></div>
                    </td>
                    <td>
                      <div style="margin-top: 6px; width:15%" class="text-dark">
                        {{ item.productPLU.PLUCode }}
                      </div>
                    </td>
                    <td style="width: 60%" class="text-dark">
                      <div class="text-dark font-weight-normal" style="margin-top: 6px">
                        <span v-if="item.vatType === 'N'">{{ item.productPLU.name || '' }} <CBadge color="danger">Non
                            VAT</CBadge></span>
                        <span v-else> {{ item.productPLU.name || '' }} </span>
                      </div>
                    </td>

                    <td style="width: 5%;" :id="'checkStockDetailProductQuantity' + index">
                      <input :id="'checkStockDetailProductQuantityInput' + index" style="width: 100%; height: 100%" type="number" min="0" v-model.number="item.quantity"
                        class="
                          form-control form-control-md
                          text-right font-weight-normal text-dark" :disabled="disabledField || isDeletedItem(item)" />
                      <p class="text-danger" v-if="Number(item.quantity < 0) ||
            Number.isNaN(parseInt(item.quantity))
            ">
                        {{ $t('dialogfound') }}
                      </p>
                    </td>
                    <td class="text-center" style="width: 2%" :id="'checkStockDetailProductRemove' + index">
                      <CButton color="danger" size="sm" @click="removeProduct(item)"
                        :disabled="disabledField || isDeletedItem(item)">
                        <CIcon name="cil-trash"></CIcon>
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="text-danger" v-if="warning != ''">{{ warning }}</p>
              <p v-if="duplicateItem != ''" class="text-danger">
                {{ duplicateItem }}
              </p>
            </CCol>
          </CRow>
          <div v-if="validateItem" class="text-danger">
            {{ validateItem }}
          </div>
          <br />
        </CCardBody>
      </CCard>

      <!-- Footer button -->
      <div class="row justify-content-end mb-4" style="margin: 0" v-if="disabledField === false">
        <CButton id="checkStockDetailDeleteDocButton" style="margin-right: 9px" class="btn col-lg-2 col btn-danger btn-block" @click="deleteModal = true"
          v-if="isRemove === true">
          {{ $t('deleteDoc') }}
        </CButton>
        <CButton id="checkStockDetailSaveDocButton" class="btn btn-success col-lg-2 col" @click="popupModal = true" :disabled="items.length === 0">
          {{ $t('save') }}
        </CButton>
      </div>
    </CCol>

    <!-- Confirm modal -->
    <CRow>
      <CModal id="checkStockDetailConfirmModal" :show.sync="popupModal" centered color="success" :title="$t('confirmSaveDoc')" :footer="footer">
        <br />
        <h4 class="text-center">
          {{ $t('confirmUpdateDocModal') }}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton id="checkStockDetailSaveButton" block color="success" v-if="loadingButton === true" v-on:click="formSubmit">
                {{ $t('save') }}
              </CButton>
              <CButton id="checkStockDetailSaveDisabledButton" block color="success" v-else-if="loadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol col="2"></CCol>
            <CCol col="4">
              <CButton id="checkStockDetailCancelButton" block color="light" @click="popupModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>

    <!-- Confirm delete modal -->
    <CRow>
      <CModal id="checkStockDetailDeleteModal" :show.sync="deleteModal" color="danger" :title="$t('confirmDeleteDoc')" centered :footer="deleteFooter">
        <br />
        <h4 class="text-center">
          {{ $t('confirmDeleteModal') }}
        </h4>
        <br />
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="4">
              <CButton id="checkStockDetailDeleteButton" block color="danger" v-if="loadingButton === true"
                v-on:click="deleteStockDocument(checkStockObjectId)">
                {{ $t('confirm') }}
              </CButton>
              <CButton id="checkStockDetailDeleteDisabledButton" block color="danger" v-else-if="loadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('confirm') }}
              </CButton>
            </CCol>
            <CCol col="2"></CCol>
            <CCol col="4">
              <CButton id="checkStockDetailDeleteCancelButton" block color="light" @click="deleteModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </CRow>

    <!-- Select product modal -->
    <CRow>
      <CModal id="checkStockDetailAddProductModal" :show.sync="AddProductModal" centered :footer="footer" size="lg" style="max-width: 915px;">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal text-dark">
              {{ $t('selectdProduct') }}
            </h4>
            <button id="checkStockDetailAddProductModalCloseButton" type="button" aria-label="Close" class="close" @click="AddProductModal = false">
              ×
            </button>
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <CRow>
              <label class="col-sm-1 col-form-label text-left text-dark">
                {{ $t('search') }}
              </label>
              <CCol style="padding-right: 0;">
                <select id="checkStockDetailAddProductSearchSelect" v-model="searchBy" name="" class="custom-select no-border-radius-left">
                  <option value="PLUCode">{{ $t('pluCode') }}</option>
                  <option value="name">{{ $t('productName') }}</option>
                  <option value="category">{{ $t('category') }}</option>
                </select>
              </CCol>
              <CCol class="pl-0 col-6">
                <template v-if="searchBy === 'category'">
                  <select id="checkStockDetailAddProductCategorySelect" v-model="selectedCategory" class="form-control text-dark no-border-radius-right">
                    <option selected value="">{{ $t('selectCategory') }}</option>
                    <option :id="'checkStockDetailAddProductCategoryOption' + category.objectId" v-for="category in dataCategory" :key="category.objectId" :value="category.objectId">
                      {{ category.name }}
                    </option>
                  </select>
                </template>
                <template v-else>
                  <input id="checkStockDetailAddProductSearchInput" class="form-control text-dark no-border-radius-right" v-model="keywordEntered" @input="searchProductPLU"
                    :placeholder="$t('searchProduct')" />
                </template>
              </CCol>
              <CCol class="p-0">
                <CButton id="checkStockDetailAddProductSearchButton" block color="success" v-if="loadingButton === true" v-on:click="searchProductPLU">
                  {{ $t('search') }}
                </CButton>
              </CCol>

              <CCol>
                <CButton id="checkStockDetailAddProductResetButton" class="btn btn-outline-secondary text-dark" style="font-weight: normal; width: inherit"
                  v-on:click="reset">
                  {{ $t('reset') }}
                </CButton>
              </CCol>
            </CRow>
            <div style="max-height: 400px; overflow-y: auto" class="mt-4">
              <DataTable id="checkStockDetailAddProductDataTable"  pagination :fields="fields" :items="autoCompleteResult" hover border clickableRows
                @row-clicked="onSelectedAutoCompleteEvent">
                <template slot="selected-header">
                  <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                    <input v-model="all" type="checkbox" @change="setAll()" class="custom-control-input success cursor"
                      id="yyy" />
                    <!-- type="checkbox" @change="setAll()"
                    // -->
                    <label class="custom-control-label cursor" for="yyy">
                    </label>
                  </div>
                </template>
                
                <template #selected="{ item, index }">
                  <td :id="'checkStockDetailAddProductDataTableItemSelected'+index" style="vertical-align: middle">
                    <div class="custom-control custom-checkbox" style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectedAutoCompleteEvent(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item, index }">
                  <td style="vertical-align: middle; border-left: hidden" :for="index">
                    <img v-if="item.remoteImagePath" :src="item.remoteImagePath"
                      style="border-radius: 2px; aspect-ratio: 4 / 3" class="img-fluid" @error="noImgUrl" />
                    <div v-else style="border-radius: 2px" :style="{
            'aspect-ratio': '4 / 3',
            backgroundColor: item.indexColor,
          }"></div>
                  </td>
                </template>
                <template #nameproduct="{ item }">
                  <td class="text-dark font-weight-normal" style="vertical-align: middle; border-left: hidden">
                    {{ item.name }}
                  </td>
                </template>
                <template #pluCode="{ item }">
                  <td class="text-dark font-weight-normal" style="vertical-align: middle; border-left: hidden">
                    {{ item.PLUCode }}
                  </td>
                </template>
                <template #categoryName="{ item }">
                  <td class="text-dark font-weight-normal" style="vertical-align: middle;border-left: hidden;">
                    {{ item.categoryName }}
                  </td>
                </template>
                <template #price="{ item, index }">
                  <td :for="index" style="vertical-align: middle; border-left: hidden" v-if="item.ProductSKU.stdCost === '' ||
            item.ProductSKU.stdCost === undefined
            ">
                    ฿ 0
                  </td>
                  <td :for="index" style="vertical-align: middle; border-left: hidden" v-else>
                    ฿ {{ item.ProductSKU.stdCost }}
                  </td>
                </template>
                <template #onhanQTY="{ item, index }">
                  <td class="text-right text-dark font-weight-normal" :for="index"
                    style="vertical-align: middle; border-left: hidden" v-if="item.ProductPLUStock.onhandQty === '' ||
            item.ProductPLUStock.onhandQty === undefined">
                    N/A
                  </td>
                  <td class="text-right font-weight-normal" :for="index"
                    style="vertical-align: middle; border-left: hidden" v-else>
                    <div v-if="floatValue(item.ProductPLUStock.onhandQty) >= 0" class="text-dark">
                      {{ item.ProductPLUStock.onhandQty }}
                    </div>
                    <div v-else class="text-danger">
                      {{ item.ProductPLUStock.onhandQty }}
                    </div>
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </DataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row">
              <div class="col-6">
                <CButton id="checkStockDetailAddProductDataTableCancelButton" class="btn btn-outline-secondary text-dark" style="font-weight: normal;  width: inherit"
                  @click="AddProductModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </div>
              <div class="col-6">
                <CButton id="checkStockDetailAddProductDataTableConfirmButton" block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('confirm') }}
                </CButton>
                <CButton id="checkStockDetailAddProductDataTableSaveButton" block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
  <!-- <div v-else>
    <access-data></access-data>
  </div> -->
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import axios from '@/services/local'
import util from '@/util/util'
import permis from '@/util/permission'
import DataTable from '@/containers/DataTable'
import pos from '@/services/local'

export default {
  components: {
    Multiselect,
    DataTable
  },
  data() {
    return {
      isSuccess: false,
      alert: false,
      data: [],
      detail: {},
      items: {},
      itemList: [],
      member: {},
      searchBy: 'name',
      label: 'name',
      keyword: '',
      placeHolderInputText: 'พิมพ์ชื่อสินค้าที่ต้องการค้นหา .​.​.',
      autoCompleteResult: [],
      autoCompleteProgress: false,
      autoCompleteText: 'name',
      autoCompleteText2: 'PLUCode',
      autoCompleteText3: 'unitName',
      autoCompleteFieldId: 'alpha3Code',
      validateShop: '',
      validateItem: '',
      popupModal: false,
      footer: '',
      deleteModal: false,
      deleteFooter: '',
      isDeleted: '',
      loadingButton: true,
      duplicateItem: '',
      warning: '',
      AddProductModal: false,
      keywordEntered: '',
      all: false,
      selectedItems: [],
      dataCategory: [],
      selectedCategory: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      return permis.findPermissionRead('inventory', '/inventory/stock-in')
    },
    isEdit() {
      return permis.findPermissionEdit('inventory', '/inventory/stock-in')
    },
    isExport() {
      return permis.findPermissionExport('inventory', '/inventory/stock-in')
    },
    isRemove() {
      return permis.findPermissionRemove('inventory', '/inventory/stock-in')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    checkStockObjectId() {
      return this.$route.params.checkStockObjectId
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    datetime() {
      return moment(String(this.detail.date)).format('DD/MM/YYYY')
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    disabledField() {
      return this.isDeleted === true || !this.isEdit
    },
    documentItems() {
      const items = this.detail.items || []
      return items.sort((a, b) => a.orderIndex - b.orderIndex);
    },
    createdBy() {
      let created = ''
      if (this.detail.created_by === undefined) {
        created = '-'
      } else {
        created = this.detail.created_by.username
      }
      return created
    },
    fields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('selectd'),
          _style: 'width:5%; vertical-align: middle;',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'name',
          label: this.$i18n.t('product'),
          _style: 'width:7%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'nameproduct',
          label: this.$i18n.t('product'),
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'pluCode',
          label: this.$i18n.t('pluCode'),
          _style: 'width:25%; vertical-align: middle;border-left: hidden',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'categoryName',
          label: this.$i18n.t('category'),
          _style: 'width:23%; vertical-align: middle;',
          _classes: 'text-left font-weight-normal text-dark',
        },
        {
          key: 'onhanQTY',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right font-weight-normal text-dark',
          _style: 'width:30%; vertical-align: middle;border-left: hidden',
        },
      ]
    },
  },
  created() {
    this.shop = this.shops.find((i) => i.objectId === this.shop)
    this.getCheckStockDetail()
    this.searchProductPLU()
    this.getCategory()
  },
  methods: {
    ...util,
    getCategory() {
      const uid = `${localStorage.getItem('shopsUid')}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        page: 1,
        limit: 1000,
      };
      const headers = {
                shopObjectId: shopObjectId,
            }

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId, {
          params, headers
        })
        .then((res) => {
          let detail = []
          let data = res.data.data
          for (let i = 0; i < data.length; i++) {
            if (data[i].enabled === true) {
              detail.push({
                id: data[i].id,
                name: data[i].name || '-',
                objectId: data[i].objectId,
              })
            }
          }
          this.dataCategory = detail
          })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    getDateLocale() {
      if (this.$i18n.locale == 'en') {
        return { lang: 'en' }
      } else {
        return { lang: 'de' }
      }
    },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    setAll() {
      let data = this.autoCompleteResult
      let select = this.selectedItems
      if (this.all) {
        data.forEach(function (item) {
          item.selected = true
          select.push(item)
        })
      } else {
        data.forEach(function (item) {
          item.selected = false
          let i = select.findIndex((x) => x.objectId === item.objectId)
          if (i != -1) {
            select.splice(i, 1)
          }
        })
      }
      this.selectedItems = select
      this.autoCompleteResult = data
    },
    AddModal() {
      this.keywordEntered = ''
      this.autoCompleteResult = []
      this.all = false
      this.searchProductPLU()
      this.AddProductModal = true
    },
    reset() {
      this.selectedItems = []
      this.all = false
      this.keywordEntered = ''
      this.searchBy = 'name'
      this.selectedCategory = ''
      this.searchProductPLU()
    },
    handlePopup() {
      if (this.detail.shop.length === 0) {
        this.popupModal = false
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      if (this.detail.items.length === 0) {
        this.popupModal = false
        this.validateItem = this.$i18n.t('selectProductPlease')
      } else {
        this.validateItem = ''
      }

      if (this.detail.items.length != 0 && this.detail.shop.length != 0) {
        this.popupModal = true
      }
    },
    removeProduct(i) {
      let items = this.detail.items
      let newItems = items.filter((item) => item.orderIndex != i.orderIndex)
      this.detail.items = newItems
      let index = this.autoCompleteResult.findIndex(
        (x) => x.objectId == i.productPLU.objectId
      )
      if (index != -1) {
        this.autoCompleteResult[index].selected = false
      }
      let find = this.selectedItems.findIndex(
        (x) => x.objectId === i.productPLU.objectId
      )
      if (find != -1) {
        this.selectedItems.splice(find, 1)
      }
    },
    getCheckStockDetail() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const checkStockObjectId = this.$route.params.checkStockObjectId
      const params = {
        shopObjectId: shopObjectId,
      }
      let itemList = []
      let detail = []
      const headers = {
        shopObjectId: this.$store.getters.shopObjectId,
      }
      axios({
        url: '/api/v2.2/' + uid + '/CheckStockDocument/' + checkStockObjectId,
        params: params,
        headers: headers,
        method: 'GET',
      }).then(async (res) => {
        const responseData = res || {}
        const data = responseData.data || {}
        const documents = data.data

        this.detail = documents

        this.detail.items.forEach((item) => {
          const productPLU = item.productPLU || {}
          if (productPLU.remoteImagePath !== undefined || productPLU.remoteImagePath !== '') {
            item.remoteImagePath = productPLU.remoteImagePath
          }
          item.indexColor = util.generateColor(productPLU.indexColor || 0)
          detail.push(item)

          itemList.push(item)
        })
        this.detail.items = detail
        this.selectedItems = itemList
        if (this.detail.deleted_at != null) {
          this.isDeleted = true
        } else {
          this.isDeleted = false
        }

        
      }).catch((error) => {
        console.log(error)
      })
    },
    onSelectedAutoCompleteEvent(item) {
      item.selected = !item.selected
      if (item.selected) {
        this.selectedItems.push(item)
      } else {
        let i = this.selectedItems.findIndex(
          (x) => x.objectId === item.objectId
        )
        if (i != -1) {
          this.selectedItems.splice(i, 1)
        }
      }
      
      let countItem = this.autoCompleteResult.length
      let coutselect = 0
      this.autoCompleteResult.forEach(function (item) {
        if (item.selected) {
          coutselect = coutselect + 1
        }
      })
      if (countItem === coutselect) {
        this.all = true
      } else {
        this.all = false
      }
    },
    searchProductPLU() {
      const uid = this.uid
      let params = {}
      if (this.searchBy === 'category' && this.selectedCategory) {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          categoryObjectId: this.selectedCategory,
        }
      } else if (this.keywordEntered != '') {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
          keyword: this.keywordEntered,
        }
      } else {
        params = {
          shopObjectId: this.$store.getters.shopObjectId,
          searchBy: this.searchBy,
        }
      }

      this.label = this.searchBy
      this.autoCompleteResult = []
      this.autoCompleteProgress = false
      let selectedItems = this.selectedItems
      // if (keywordEntered.length > 0) {
      this.autoCompleteProgress = true
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      axios({
        url: '/api/v1.0/' + uid + '/getproductpluwithstock',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((response) => {
          this.alert = false
          this.all = false
          let newData = []

          response.data.data.forEach(function (item) {
            if (selectedItems.length > 0) {
              let i = selectedItems.findIndex(
                (x) => x.objectId === item.objectId
              )
              if (i == -1) {
                item.selected = false
              } else {
                item.selected = true
              }
            } else {
              item.selected = false
            }
            const unit = item.unit || {}
            item.unitName = unit.name || '-'
            const category = item.category || {}
            item.categoryName = category.name || '-'
            item.indexColor = util.generateColor(item.ProductSKU.indexColor)
            if (item.selected == false) {
              newData.push(item)
            }
          })
          this.autoCompleteResult = newData
          this.autoCompleteProgress = false
        })
        .catch((e) => {
          console.log(e)
          this.alert = true
          this.autoCompleteProgress = false
          this.autoCompleteResult = []
        })
    },
    formSubmit(e) {
      this.loadingButton = false
      e.preventDefault()
      const uid = this.uid
      const checkStockObjectId = this.$route.params.checkStockObjectId
      let discount = ''
      function generateUniqueID() {
        const characters = 'abcdef0123456789';
        let id = '';
        for (let i = 0; i < 24; i++) {
          id += characters[Math.floor(Math.random() * characters.length)];
        }
        return id;
      }
      const dataItem = []
      this.detail.items.forEach((item) => {
        dataItem.push({
          objectId: item.checkStockDocumentId ? item.objectId : generateUniqueID(),
          id: item.checkStockDocumentId ? item.id : generateUniqueID(),
          created_by_id: 'User001',
          updated_by_id: 'User001',
          checkStockDocumentId: this.detail.id,
          checkStockDocument: {
            objectId: this.detail.objectId,
            id: this.detail.id,
            checkStockNo: this.detail.checkStockNo,
          },
          ...item,
          created_by: this.detail.created_by,
          updated_by: this.detail.updated_by
        });
      });
      let data = {
        objectId: this.detail.objectId,
        id: this.detail.id,
        created_at: this.detail.created_at,
        created_by: this.detail.created_by,
        created_by_id: 'User001',
        note: this.detail.note,
        shop: {
          id: this.detail.shop.id,
          objectId: this.detail.shop.objectId,
          shopName: this.detail.shop.shopName,
          branchName: this.detail.shop.branchName,
        },
        shopId: this.detail.shop.id,
        items: dataItem,
        uid: this.uid,
        updated_at: new Date(),
        updated_by: this.detail.updated_by,
        updated_by_id: 'User001'
      }

      if (data.shopId === undefined) {
        this.validateShop = this.$i18n.t('selectShopPlease')
      } else {
        this.validateShop = ''
      }

      if (data.items.length === 0) {
        this.validateItem = this.$i18n.t('selectProductPlease')
      } else {
        this.validateItem = ''
      }

      const headers = {
        shopObjectId: this.shopObjectId,
      }

      if (data.shopId != undefined && data.items.length != 0) {
        axios({
          method: 'patch',
          url: '/api/v2.2/' + uid + '/CheckStockDocument/' + checkStockObjectId,
          params: { shopObjectId: this.shopObjectId, },
          data: data,
          headers: headers,
        })
          .then(() => {
            // setTimeout(() => {
            this.isSuccess = true
            this.$router.push('/inventory/check-stock')
            // }, 5000);
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    deleteStockDocument(checkStockObjectId) {
      this.loadingButton = false
      const uid = this.uid
      const plan = this.users.currentPlan


      const headers = {
        shopObjectId: this.shopObjectId,
      }
      axios({
        method: 'delete',
        url:
          '/api/v2.2/' + uid + '/CheckStockDocument/' + checkStockObjectId,
        params: { plan: plan  },
        headers: headers,
      })
        .then(() => {
          setTimeout(() => {
            (this.deleteModal = false),
              this.$router.push('/inventory/check-stock')
          }, 5000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onCheckboxChange() {
      let data = this.selectedItems
      let items = this.detail.items
      let uid = this.uid
      let shop = this.shop
      data.forEach(function (item, index) {
        let stdCost = 0
        let SKURatio = 1
        let previousOnhand = 0
        if (item.selected) {
          if (
            item.ProductSKU.stdCost === undefined ||
            item.ProductSKU.stdCost === null
          ) {
            stdCost = 0
          } else {
            stdCost = item.ProductSKU.stdCost
          }
          if (item.SKURatio == undefined) {
            SKURatio = 1
          } else {
            SKURatio = item.SKURatio
          }
          if (items.length > 0) {
            let i = items.findIndex(
              (x) => x.productPLU.objectId === item.objectId
            )
            if (i == -1) {
              items.push({
                uid: uid,
                orderIndex: index,
                productPLU: {
                  id: item.id,
                  objectId: item.objectId,
                  PLUCode: item.PLUCode,
                  name: item.name,
                  unit: item.unit,
                  SKURatio: item.SKURatio
                },
                productPLUId: item.id,
                productSKU: {
                id: item.ProductSKU.id,
                objectId: item.ProductSKU.objectId,
                SKUName: item.ProductSKU.SKUName,
                SKUPrice: item.ProductSKU.SKUPrice,
                indexColor: item.ProductSKU.indexColor,
                skuType: item.ProductSKU.skuType,
                vatType: item.ProductSKU.vatType
              },
                productSKUId: item.ProductSKU.id,
                previousOnhand: previousOnhand,
                // shop: {
                //   id: shop.id,
                //   objectId: shop.objectId,
                //   shopName: shop.shopName,
                //   branchName: shop.branchName,
                // },
                // shopId: shop.id,
                quantity: 0,
                unit: {
                  name: item.unitName,
                },
                SKURatio: item.SKURatio,
                remoteImagePath: item.remoteImagePath,
                indexColor: item.indexColor,
              })
            }
          } else {
            items.push({
              uid: uid,
              orderIndex: index,
              productPLU: {
                id: item.id,
                objectId: item.objectId,
                PLUCode: item.PLUCode,
                name: item.name,
                unit: item.unit,
                SKURatio: item.SKURatio
              },
              productPLUId: item.id,
              productSKU: {
                id: item.ProductSKU.id,
                objectId: item.ProductSKU.objectId,
                SKUName: item.ProductSKU.SKUName,
                SKUPrice: item.ProductSKU.SKUPrice,
                indexColor: item.ProductSKU.indexColor,
                skuType: item.ProductSKU.skuType,
                vatType: item.ProductSKU.vatType
              },
              productSKUId: item.ProductSKU.id,
              previousOnhand: previousOnhand,
              // shop: {
              //   id: shop.id,
              //   objectId: shop.objectId,
              //   shopName: shop.shopName,
              //   branchName: shop.branchName,
              // },
              // shopId: shop.id,
              quantity: 0,
              unit: {
                name: item.unitName,
              },
              SKURatio: item.SKURatio,
              remoteImagePath: item.remoteImagePath,
              indexColor: item.indexColor,
            })
          }
        } else {
          let index = items.findIndex(
            (x) => x.productPLU.objectId === item.objectId
          )
          if (index != -1) {
            items.splice(index, 1)
          }
        }
      })
      this.detail.items = items
      this.AddProductModal = false
      this.all = false
    },
    toDateString(date) {
      return moment(String(date)).format('DD/MM/YYYY HH:mm:ss')
    },
    isDeletedItem(item) {
      return item.deleted_at != null
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../assets/styles/inventory.css" scoped></style>

<style>
.no-border-radius-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.no-border-radius-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.square-box {
  position: relative;
  width: 20%;
  overflow: hidden;
  border-radius: 5px;
}

.square-box:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

/* Hidden arrow  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
